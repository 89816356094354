import React from "react";
import { a, an } from "utilities/propTypesShorthand";
import getComponentName from "utilities/componentName";

import "./styles";

/* eslint-disable import/extensions */
// #YALLINTEXAS images
import imageHashtag1 from "assets/images/hashtag/photos/1.jpg";
import imageHashtag2 from "assets/images/hashtag/photos/2.jpg";
import imageHashtag3 from "assets/images/hashtag/photos/3.jpg";
import imageHashtag4 from "assets/images/hashtag/photos/4.jpg";
import imageHashtag5 from "assets/images/hashtag/photos/5.jpg";
import imageHashtag6 from "assets/images/hashtag/photos/6.jpg";
import imageHashtag7 from "assets/images/hashtag/photos/7.jpg";
import imageHashtag8 from "assets/images/hashtag/photos/8.jpg";
/* eslint-enable import/extensions */

// Get component name from path
const componentName = getComponentName(import.meta.url);

const App = (props) => {
  const {
    content,
    options,
  } = props;

  return (
    <div className={options.className}>
      <header className="header theme--dark">
        <h1 className="header__logo">Y&apos;all In for Texas Dance Halls</h1>
        <p className="header__tagline">
          Let&apos;s keep tunes playin&apos;, boots
          <br className="global__desktop-only" />
          {" "}
          scootin&apos;, and doors open.
        </p>
      </header>
      <main>
        <section className="intro theme--dark">
          <div className="intro__logo icon--logo">
            <span className="global__visually-hidden">Texas Dance Hall Preservation est&apos;d 2007</span>
          </div>
          <h2 className="intro__heading heading">
            Help us preserve the dance hall
            <br className="global__desktop-only" />
            {" "}
            way of life. Y’all in?
          </h2>
          <p>Calling all Texas music lovers, beer drinkers, bouquet tossers, barbeque buffs, cumbia queens, and first-time two-steppers.</p>
          <p>Dance Halls are where Texas legends like you have come to life for over 150 years. But after too many nights of empty dance floors, our halls need a hand. So let&apos;s all of us pitch in—right here, right now—to keep dance halls open and legends alive for another 150 years.</p>
          <a
            className="global__button global__button--default intro__donate"
            href="https://texasdancehallpreservation.networkforgood.com/projects/153941-y-all-in"
          >
            <span>Donate Now</span>
          </a>
        </section>
        <section className="help theme--light">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="help__video"
            height="180"
            frameBorder="0"
            src="https://www.youtube.com/embed/oOnH_SZe6Ko"
            title="Ray Benson&apos;s call to save our historic dance halls."
            width="320"
          />
          <h2 className="help__heading heading">
            Y&apos;all. I&apos;m so
            {" "}
            <em>in</em>
            !
          </h2>
          <p className="help__subheading subheading">
            How can I help preserve
            <br className="global__mobile-only" />
            {" "}
            Texas Dance Halls?
          </p>
          <ul className="help__options">
            <li className="help__options__option help__options__option--give">
              <h3 className="subheading">Give What You Can</h3>
              <p>Make a contribution today. Please and thank ya.</p>
              <ol className="list--buttons">
                <li>
                  <a
                    className="global__button global__button--alt"
                    href="https://texasdancehallpreservation.networkforgood.com/projects/153941-y-all-in?donation_amount=10"
                  >
                    <span>
                      <sup>$</sup>
                      10
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    className="global__button global__button--alt"
                    href="https://texasdancehallpreservation.networkforgood.com/projects/153941-y-all-in?donation_amount=25"
                  >
                    <span>
                      <sup>$</sup>
                      25
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    className="global__button global__button--alt"
                    href="https://texasdancehallpreservation.networkforgood.com/projects/153941-y-all-in?donation_amount=50"
                  >
                    <span>
                      <sup>$</sup>
                      50
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    className="global__button global__button--alt"
                    href="https://texasdancehallpreservation.networkforgood.com/projects/153941-y-all-in?donation_amount=100"
                  >
                    <span>
                      <sup>$</sup>
                      100+
                    </span>
                  </a>
                </li>
              </ol>
            </li>
            <li className="help__options__option help__options__option--share">
              <h3 className="subheading">Share with Friends</h3>
              <p>Tell the world you&apos;re in and ask friends to follow your lead.</p>
              <ol className="list--buttons">
                <li>
                  <a
                    className="global__button global__button--alt"
                    href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.yallintexas.com%2F"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="icon--arrow" />
                    <span className="global__visually-hidden">Share</span>
                  </a>
                </li>
              </ol>
            </li>
            <li className="help__options__option help__options__option--save">
              <h3 className="subheading">Save Us a Dance</h3>
              <p>When you&apos;re ready, get on out to a dance hall in real life.</p>
            </li>
          </ul>
        </section>
        <section className="campaign theme--dark">
          <dl>
            <dt>Donation goal</dt>
            <dd>$25,000</dd>
          </dl>
          <div className="progress-bar">
            <div className="progress-bar__progress" />
          </div>
          <dl>
            <dt>
              Amount raised
              <br />
              {" "}
              to date
            </dt>
            <dd>$275</dd>
            {/*
              <dt>
                Remaining
                <br />
                {" "}
                to goal
              </dt>
              <dd>$99,900</dd>
            */}
            {/*
              <dt>
                Dance halls
                <br />
                {" "}
                helped
              </dt>
              <dd>1</dd>
            */}
          </dl>
        </section>
        <section className="featured-halls theme--light">
          <h2 className="featured-halls__heading">Featured halls</h2>
          <p className="featured-halls__subheading subheading">
            See why these storied establishments are
            <br className="global__desktop-only" />
            {" "}
            worth fighting for.
          </p>
          <ul className="featured-halls__halls">
            <li className="featured-halls__halls__hall featured-halls__halls__hall--hye h-card">
              <a
                className="u-url"
                href="https://texasdancehall.org/hye-dance-hall/"
              >
                <h3 className="featured-halls__subheading p-org">Hye Dance Hall</h3>
                <address className="featured-halls__address subheading h-adr">
                  <span className="p-locality">Hye</span>
                  <span className="p-region">Texas</span>
                </address>
              </a>
            </li>
            <li className="featured-halls__halls__hall featured-halls__halls__hall--peters-hacienda h-card">
              <a
                className="u-url"
                href="https://texasdancehall.org/peters-hacienda-community-hall/"
              >
                <h3 className="featured-halls__subheading p-org">
                  Peter&apos;s Hacienda
                  <br />
                  {" "}
                  Community Hall
                </h3>
                <address className="featured-halls__address subheading h-adr">
                  <span className="p-locality">Sealy</span>
                  <span className="p-region">Texas</span>
                </address>
              </a>
            </li>
            <li className="featured-halls__halls__hall featured-halls__halls__hall--coupland h-card">
              <a
                className="u-url"
                href="https://texasdancehall.org/old-coupland-inn-dance-hall/"
              >
                <h3 className="featured-halls__subheading p-org">Coupland Dance Hall</h3>
                <address className="featured-halls__address subheading h-adr">
                  <span className="p-locality">Coupland</span>
                  <span className="p-region">Texas</span>
                </address>
              </a>
            </li>
          </ul>
          <a
            className="global__button global__button--default"
            href="https://texasdancehall.org/about-dance-halls/dance-halls-map/"
          >
            <span>See more</span>
          </a>
        </section>
        <section className="about theme--dark">
          <h2 className="about__heading heading">About Texas Dance Hall Preservation</h2>
          <h3 className="about__subheading subheading">
            Every dollar goes a long way in preserving
            <br className="global__desktop-only" />
            {" "}
            a treasured Texas legacy
          </h3>
          <p>Texas Dance Hall Preservation, Inc. (TDHP), is a statewide nonprofit organization dedicated to preserving historic dance halls and the authentic Texas heritage, music, and culture they inspire.</p>
          <p>Your generous donations go directly to the Y&apos;all In Historic Preservation Fund, which helps dance halls with the most urgent need cover essential expenses like utility bills. With lights on and doors open in halls across Texas, we can make darn sure the music never stops playing.</p>
          <a
            className="global__button global__button--default global__button--wide"
            href="https://texasdancehall.org/about-texas-dance-hall-preservation/"
          >
            <span>
              Learn about
              <br className="global__mobile-only" />
              {" "}
              your impact
            </span>
          </a>
        </section>
        <section className="hashtag theme--light">
          <h2 className="hashtag__heading heading">
            Share your legendary
            <br className="global__desktop-only" />
            {" "}
            dance hall moments
          </h2>
          <h3 className="hashtag__subheading subheading">
            <a href="https://www.instagram.com/texasdancehallpreservationinc/">#YallinTexas</a>
          </h3>
          <ul className="hashtag__images">
            <li className="hashtag__images__image hashtag__images__image--alt">
              <img
                alt=""
                src={imageHashtag1}
              />
            </li>
            <li className="hashtag__images__image global__desktop-only">
              <img
                alt=""
                src={imageHashtag2}
              />
            </li>
            <li className="hashtag__images__image global__desktop-only">
              <img
                alt=""
                src={imageHashtag3}
              />
            </li>
            <li className="hashtag__images__image global__desktop-only">
              <img
                alt=""
                src={imageHashtag4}
              />
            </li>
            <li className="hashtag__images__image global__desktop-only">
              <img
                alt=""
                src={imageHashtag5}
              />
            </li>
            <li className="hashtag__images__image hashtag__images__image--alt global__desktop-only">
              <img
                alt=""
                src={imageHashtag6}
              />
            </li>
            <li className="hashtag__images__image global__desktop-only">
              <img
                alt=""
                src={imageHashtag7}
              />
            </li>
            <li className="hashtag__images__image global__desktop-only">
              <img
                alt=""
                src={imageHashtag8}
              />
            </li>
          </ul>
        </section>
      </main>
      <footer className="footer theme--dark">
        <div className="footer__column">
          <div className="footer__logo icon--logo icon--logo--highlight">
            <span className="global__visually-hidden">Texas Dance Hall Preservation est&apos;d 2007</span>
          </div>
          <address className="footer__address h-card">
            <span className="footer__heading p-org">
              Texas Dance Hall
              <br className="global__desktop-only" />
              {" "}
              Preservation, Inc.
            </span>
            <div className="footer__address__address h-adr">
              <span className="footer__address__address__street-address p-street-address">3005 S Lamar Blvd, Ste D-109 #315</span>
              <span className="footer__address__address__city p-locality">Austin</span>
              {" "}
              <span className="footer__address__address__state p-region">TX</span>
              {" "}
              <span className="footer__address__address__zipcode p-postal-code">78704</span>
              <span className="footer__address__address__telephone p-tel">512-400-4315</span>
            </div>
            <ol className="footer__social list--buttons">
              <li>
                <a
                  className="global__button global__button--alt global__button--alt--inverse p-url"
                  href="https://www.facebook.com/texasdancehallpreservation/"
                >
                  <span className="icon icon--social icon--social--facebook" />
                  <span className="global__visually-hidden">Facebook</span>
                </a>
              </li>
              <li>
                <a
                  className="global__button global__button--alt global__button--alt--inverse p-url"
                  href="https://www.instagram.com/texasdancehallpreservationinc/"
                >
                  <span className="icon icon--social icon--social--instagram" />
                  <span className="global__visually-hidden">Instagram</span>
                </a>
              </li>
            </ol>
          </address>
        </div>
        <div className="footer__column">
          <section className="newsletter">
            <h2 className="footer__heading newsletter__heading">
              Sign up for our
              <br className="global__mobile-only" />
              {" "}
              emails
            </h2>
            <p>By signing up, you&apos;ll receive the newsletter and an occasional e-blast about special events or dance hall happenings!</p>
            <a
              className="global__button global__button--default"
              href="https://texasdancehall.org/email-signup/"
              type="submit"
            >
              <span>Sign up now</span>
            </a>
            {/*
              <form
                className="footer__form"
                id="form__newsletter"
              >
                <input
                  className="input--text"
                  placeholder="Email address"
                  spellCheck="false"
                  type="email"
                />
              </form>
            */}
          </section>
        </div>
      </footer>
    </div>
  );
};

App.defaultProps = {
  content: {
    header: componentName.titlecase,
  },
  options: {
    className: componentName.class,
  },
};

App.propTypes = {
  content: a.shape({
    header: a.string,
  }),
  options: a.shape({
    className: a.string,
  }),
};

export default App;
